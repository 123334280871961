<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
     
      <div class="d-flex flex-wrap">
        <!-- <h4 class="mb-1">
        {{ userData.fullName }}
      </h4> -->
      <b-img v-if="newCategoryImage === ''"
              src="/img/logoappmenu2.png"
              class="card-img-top" style="width: 20% !important;"
              @click="$refs.refInputEl.click()"
            />
            <b-img v-else
              :src="newCategoryImage"
              class="card-img-top" style="width: 20% !important;"
            />
            <input class="d-none" @change="getNewCategoryImg" type="file" ref="refInputEl" id="anaxaFile" />
            <div style="padding-left: 15px !important; width: 25% !important;">
            <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
          <b-form-group
            label="Documento"
            label-for="full-name"
          >
            <b-form-input
              id="full-name" @change="setGovId(0, ...arguments)"
               style="width: 100%;"
            />
          </b-form-group>
          </div>
        <div style="padding-left: 15px !important; width: 25% !important;">
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
          <b-form-group
            label="Nombre(s)"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </div>
        <div style="padding-left: 15px !important; width: 28% !important;">
          <b-form-group
            label="Area"
            label-for="mobile"
          >
                  <v-select 
                  @input="setUserArea"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerAreasList"
                    label="name"
                    :clearable="false"
                  >
                    <!-- <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Crear Usuario</span>
                      </li>
                    </template> -->
                  </v-select>

          </b-form-group>
        

          <b-form-group
            label="Apellido(s)"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.fullName"
            />
          </b-form-group>

        </div>
        <div style="margin-top: 2%; margin-left: 2%; width: 100%;">
          <b-row>
          <b-col md="4">
            <b-form-group
            label="Banco "
            label-for="full-name" 
          >
          <v-select 
                  @input="setUserBank"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="computedBankList"
                    label="name"
                    :clearable="false"
                  />
          </b-form-group>              

          Pais
        <b-form-group
          >
                  <v-select
                  @input="setCountry"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countries"
                    label="name_spanish"
                    :clearable="false"
                  >
                    <!-- <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Crear Usuario</span>
                      </li>
                    </template> -->
                  </v-select>

          </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
            label="# Cuenta "
            label-for="full-name"
          >
            <b-form-input
              id="full-name" @change="setGovId(1, ...arguments)"
              style="width: 100%;"
            />
          </b-form-group>   
          Ciudad
            <v-select
            label="city_name"
            @input="setCity"
              :options="cities"
              :clearable="false" v-if="flagCities"
            />           
            </b-col>
            <b-col md="3">
              <b-form-group
            label="Compañia"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="userData.company"
            />
          </b-form-group>
          <b-form-group
            label="Contraseña Primera Vez"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.newPassword"
            />
          </b-form-group>
</b-col>
            

           
          </b-row>

        </div>

      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>


                <!-- Field: Email -->
        

        <!-- Field: Status -->

        <!-- Field: Role -->
       

        <!-- <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Ciudad"
            label-for="user-role"
          >
            <v-select
            label="name_spanish"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cities"
              :clearable="false"
            />
          </b-form-group>
        </b-col> -->

        <b-col
          md="4"
        >

        </b-col>  
        <!-- <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Role de Usuario"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Email -->
                <!-- Field: Email -->
                <b-col md="6">
                  <!-- <div style="width: 100%; height: 100%;">
                  <MapBasicPosition @setNewLocation="setNewLocation" :locate_service_provider="{
                customer: {
                  location: {
                    lat: 4.7032197228260175,
                    lng: -74.03747129203830,
                  },
                  full_name: 'nuevo '
                }
              }" />  </div> -->
                </b-col>
        
        <b-col md="2" style="padding-top: 3%;">
          <div v-if="userData.id !== 0">
            
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateUserData"
      >
        Guardar Cambios
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Cancelar
      </b-button>
    </div>
    <div v-else style="padding-top: 0% !important;">
      
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateUserData"
      >
        Crear Usuario
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      >
        Limpiar
      </b-button>
    </div>
        </b-col>

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->

    <!-- Action Buttons -->
   
  </div>
</template>

<script>
import MapBasicPosition from '@/lavarclick/components/maps/MapBasicPosition.vue';
import {
  BButton, BMedia, BAvatar, BImg, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, computed } from '@vue/composition-api'
import useUsersList from './useUsersList'
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';

export default {
  components: {
    BButton, 
    MapBasicPosition,
    BMedia,
    BAvatar,
    BRow,
    BCol, BImg,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customerAreasList: [],
    countries: []
  },
  setup(props) {
    let newCategoryImage = ref('');
        const getNewCategoryImg = (event) => {

          const file = event.target.files[0];
          const reader = new FileReader();
        reader.onloadend = () => {
            newCategoryImage.value = reader.result;
            userData.avatar = newCategoryImage.value+'';
            console.log('edita ', userData)
            // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
        };
        reader.readAsDataURL(file); 
        };
    let banksList = ref([]);
    const computedBankList = computed({
      get: () => { return banksList.value },
      set: (newList) => {
        banksList.value = newList;
      }

    })
    const { resolveUserRoleVariant } = useUsersList()
    const accountsManager = new AccountsManager();
    const ecommerce = new ECommerce();
    let selectedCountry = {};

    const newUserPassword = {
      password: 'aabb'
    };

    const roleOptions = [
      { label: 'Admin CONSOLE', value: 'admin' },
      { label: 'Admin BIZ', value: 'adminbiz' },
      { label: 'Cliente Empresarial', value: 'company' },
      { label: 'Cliente Natural', value: 'person' },
      { label: 'Afiliado', value: 'partner' },
    ]

    const statusOptions = [
      { label: 'Pausado', value: 'pending' },
      { label: 'Activo', value: 'active' },
      { label: 'Inactivo', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64

    })

    let cities = [];
    let flagCountries = false;
    let flagCities = false;

    let location = {
      fk_city: 0,
      fk_country: 0,
      lat: 4.7032197228260175, 
      lng: -74.03747129203825,
      name: '',
      description: '',
      postal_address: '',
    };


    return {
      resolveUserRoleVariant,
      avatarText,
      newUserPassword,
      roleOptions,
      statusOptions,
      flagCountries,
      cities,
      permissionsData,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      accountsManager,
      flagCities,
      location,
      selectedCountry, ecommerce, computedBankList, newCategoryImage , getNewCategoryImg
    }
  },
  mounted() {

    console.log('llegue');
    this.userData.account = ['', '', ''];
    this.ecommerce.getBanks().then((banksList) => {
      this.computedBankList = banksList;
      console.log('bancos ', banksList);
    })


  },
    methods: {

      setGovId(index, eventx) {
        this.userData.account[index] = eventx;

        console.log('enzimas ', eventx);
      },

      setNewLocation(newLocation) {
        this.userData.location.lat = newLocation.lat;
        this.userData.location.lng = newLocation.lng;
        console.log(this.userData);
      },  

      setUserBank(bank) {

        console.log('bank', bank);
        this.userData.account[2] = bank.name;

      },

      stopEvent(eventx) {
        console.log('nada', eventx)

      },

    setCountry(country) {
      // console.log('into ', country.id_ecommerce_countries);
      this.location.fk_country = country.id_ecommerce_countries;
      this.location.country_name = country.name_spanish;

      this.accountsManager.getCities(country.id_ecommerce_countries).then((citiesList) => {
        this.cities = citiesList;
        this.flagCities = true;
        console.log('organismo2', this.cities);

      });

      console.log('organismono');
    },
    
    setCity(city) {

      this.location.fk_city = city.id_ecommerce_cities;
      this.location.city_name = city.city_name;
      this.userData.location = this.location;
      this.userData.user_info.dob = this.location.city_name+','+this.location.country_name
      console.log(this.userData);
    },

    setUserArea(company_area) {
      this.userData.company_area = company_area;
    },
    updateUserData() {
     this.$emit('updateUserInfo', this.userData);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
