<template>
    <b-modal
      id="modal-primary"
      hide-footer
      modal-class="modal-primary"
      centered
      size="lg"
      title="Formulario Nuevo Usuario LavarClick !"
      ref="modalCategoryForm" @hidden="editCategoryHidden">

      <div style="width: 100% !important">    
    <b-tabs
      v-if="flag"
      pills
    >

      <!-- Tab: Account -->

      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />

          <span class="d-none d-sm-inline">Informacion Esencial</span>
        </template>

        <user-edit-tab-account @updateUserInfo="updateUserInfo(1, ...arguments)"
          :user-data="newUserObject" :customerAreasList="customerAreasList" :countries="countries"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Informacion Catalogo</span>
        </template>
        <user-edit-tab-information :user-data="newUserObject" class="mt-2 pt-75" @updateUserInfo="updateUserInfo(2, ...arguments)" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Datos de Ubicacion</span>
        </template>
        <user-edit-tab-social :user-data="newUserObject" class="mt-2 pt-75" @updateUserInfo="updateUserInfo(3, ...arguments)" />
      </b-tab>
    </b-tabs>
  </div>


      </b-modal>
</template>
<script>

import {
  BTab, BTabs, BCard, BAlert, BLink, BImg, BRow, BCol, BModal
} from 'bootstrap-vue'
import User from '@/lavarclick/ecommerce/User.ts';
import store from '@/store'
import userStoreModule from './userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'
import UsersGeneralList from './UsersGeneralList.vue';
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';


export default {

    components: {

        BTab, BTabs, BCard, BAlert, BLink, BImg, BRow, BCol, BModal,UsersGeneralList, 
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial
    },

    setup() {

     

        let newUserObject = new User();
       
        const userData = JSON.parse(localStorage.getItem('userData'));
        let customerAreasList = [];
        const accountsManager = new AccountsManager();
        let flag = false;
        let countries = [];
    
        return {
            newUserObject,
            customerAreasList,
            userData,
            accountsManager,
            flag,
            countries
            
        }
    },
    mounted() {

        this.newUserObject.customer = this.userData.customer;

        this.accountsManager.getCustomerAreas(1).then((customerAreas) => {
        this.customerAreasList = customerAreas;

        this.accountsManager.getCountries(1).then((countriesList) => {
          this.countries = countriesList;
                  //   this.accountsManager.getEmployeePartners({
        //       id_current_user: JSON.parse(localStorage.getItem('userData')).id,
        //       id_customer: 1

        //   }).then((partners) => {

        // this.employeePartners = partners;
        console.log('sip', customerAreas, countriesList);
          this.flag = true;
          this.loadMap();
        });




        // });
        });



    },
    methods: {
      async loadMap() {
console.log('hola');

},
        editCategoryHidden(eventx) {

        },
        updateUserInfo(index, eventx) {

            this.$emit('updateUser', {
                infoTypeIndex: index,
                userData: this.newUserObject
            })

        }
    }
}

</script>
<style lang="scss">


</style>