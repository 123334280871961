var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalCategoryForm",attrs:{"id":"modal-primary","hide-footer":"","modal-class":"modal-primary","centered":"","size":"lg","title":"Formulario Nuevo Usuario LavarClick !"},on:{"hidden":_vm.editCategoryHidden}},[_c('div',{staticStyle:{"width":"100% !important"}},[(_vm.flag)?_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Informacion Esencial")])]},proxy:true}],null,false,790306464)},[_c('user-edit-tab-account',{staticClass:"mt-2 pt-75",attrs:{"user-data":_vm.newUserObject,"customerAreasList":_vm.customerAreasList,"countries":_vm.countries},on:{"updateUserInfo":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateUserInfo.apply(void 0, [ 1 ].concat( argsArray ))}}})],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Informacion Catalogo")])]},proxy:true}],null,false,2206882713)},[_c('user-edit-tab-information',{staticClass:"mt-2 pt-75",attrs:{"user-data":_vm.newUserObject},on:{"updateUserInfo":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateUserInfo.apply(void 0, [ 2 ].concat( argsArray ))}}})],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"Share2Icon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Datos de Ubicacion")])]},proxy:true}],null,false,3616851060)},[_c('user-edit-tab-social',{staticClass:"mt-2 pt-75",attrs:{"user-data":_vm.newUserObject},on:{"updateUserInfo":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateUserInfo.apply(void 0, [ 3 ].concat( argsArray ))}}})],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }